import { ref, computed } from 'vue';
import { legacyConfigService } from '@/services/legacy-config-service';
import type { LegacyConfig } from '@/models/legacy-config';

export function useLegacyConfig() {
  const config = ref<LegacyConfig | null>(null);

  legacyConfigService.getAll().then((loadedConfig) => {
    config.value = loadedConfig;
  });

  const legacyConfig = computed<LegacyConfig | null>(() => config.value || null);
  const isLegacyConfigLoaded = computed(() => config.value !== null);

  return {
    legacyConfig,
    isLegacyConfigLoaded,
    topbarLogo: computed(() => legacyConfig.value?.topbarLogo)
  };
}
