class CookieService {
  get(name: string): string | null {
    return (
      document.cookie
        .split(';')
        .find((row) => row.trim().startsWith(`${name}=`))
        ?.split('=')[1] || null
    );
  }

  set(name: string, value: string, days?: number): void {
    const expires = days ? `; expires=${new Date(Date.now() + days * 864e5).toUTCString()}` : '';
    document.cookie = `${name}=${value}${expires}; path=/`;
  }

  delete(name: string): void {
    document.cookie = `${name}=; expires=${new Date(0).toUTCString()}; path=/`;
  }
}

export const cookieService = new CookieService();
