import { legacyConfigService } from './legacy-config-service';

class LegacyHttpService {
  async get<T>(url: string): Promise<T> {
    return this.fetchJson<T>(url);
  }

  async post<T>(url: string, body: any): Promise<T> {
    return this.fetchJson<T>(url, {
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  private async fetchJson<T>(url: string, options: RequestInit = {}): Promise<T> {
    const baseUrl = (await legacyConfigService.get('apiServer')) + '/api';
    const response = await fetch(baseUrl + url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers
      }
    });

    const parsedJson = await response.json();

    if (parsedJson.status !== 200) {
      throw new Error(`${parsedJson.status} - ${parsedJson.error}`);
    }

    return parsedJson;
  }
}

export const legacyHttpService = new LegacyHttpService();
