<script setup lang="ts">
import { useGlobalI18n } from '@/composables/use-global-i18n';
import { useStore } from '@/store/store';
import { onMounted, ref, watch } from 'vue';

const { t } = useGlobalI18n();

const store = useStore();
const expanded = ref(false);
const left = ref(true);

watch(expanded, () => (store.debugWindowExpanded = expanded.value));
watch(left, () => (store.debugWindowLeft = left.value));

onMounted(() => {
  expanded.value = store.debugWindowExpanded;
  left.value = store.debugWindowLeft;
});

function formatMoney(number: number) {
  return new Intl.NumberFormat('sv-SE', {}).format(number);
}
</script>

<template>
  <div class="vm">
    <div class="debug-container box py-5 pl-5 pr-0" :class="{ 'is-left': left }">
      <div
        class="toggle-controller is-flex is-justify-content-space-between is-align-items-center pr-5"
        :class="{ 'mb-5': expanded }"
      >
        <p class="subtitle my-0 has-text-weight-semibold">Debug API 2.0</p>
        <div>
          <button class="button" @click="left = !left">Flytta</button>
          <button class="button is-primary ml-3" @click="expanded = !expanded">
            {{ expanded ? 'Stäng' : 'Öppna' }}
          </button>
        </div>
      </div>
      <div class="toggle-container pr-5" :class="{ 'is-expanded': expanded }">
        <p class="has-text-weight-semibold my-1">Företag</p>
        <pre class="mb-4 mb-6">{{ store.company }}</pre>
        
        <pre class="mt-6">{{ store.balanceSheet }}</pre>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.debug-container {
  position: fixed;
  z-index: 9999;
  width: 450px;
  bottom: 10px;
  right: 10px;
  left: auto;
  &.is-left {
    left: 10px;
    right: auto;
  }
  .toggle-container {
    max-height: 60vh;
    overflow: auto;
    display: none;
    &.is-expanded {
      display: block;
    }
    pre {
      border-radius: var(--bulma-box-radius);
      background-color: #333;
      color: white;
    }
  }

  .value {
    min-width: 80px;
    display: inline-block;
  }
}
</style>
