import { legacyConfigService } from './legacy-config-service';

class HttpService {
  async get<T>(url: string): Promise<T> {
    return this.fetchJson<T>(url);
  }

  async post<T>(url: string, body: any): Promise<T> {
    return this.fetchJson<T>(url, {
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  private async fetchJson<T>(url: string, options: RequestInit = {}): Promise<T> {
    const baseUrl = await legacyConfigService.get('apiServerV2');
    const response = await fetch(baseUrl + url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }

    return response.json();
  }
}

export const httpService = new HttpService();
