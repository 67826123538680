<script setup lang="ts">
import AppIcon from '@/components/app-icon.ce.vue';
import { companyService, type Company } from '@/services/company-service';
import { useStore } from '@/store/store';
import { legacyEmit } from '@/utils/legacy-emit';
import { mdiMagnify, mdiStarFourPoints } from '@mdi/js';
import { watchDebounced } from '@vueuse/core';
import { ref, watch } from 'vue';

const open = ref(true);
const loading = ref(false);
const errorMessage = ref('');
const infoMessage = ref('');

const dataSource = ref('UCBI');
const searchTerm = ref('');

const listedCompanies = ref<Company[]>([]);
const showingCompanyHierarchy = ref(false);

const store = useStore();

watchDebounced(searchTerm, debouncedSearch, { debounce: 350 });

async function debouncedSearch() {
  errorMessage.value = '';
  infoMessage.value = '';
  listedCompanies.value = [];
  showingCompanyHierarchy.value = false;

  if (!searchTerm.value || searchTerm.value.length <= 2) {
    return;
  }

  try {
    loading.value = true;
    listedCompanies.value = await companyService.search({
      dataSource: dataSource.value,
      searchTerm: searchTerm.value,
      locale: 'SE'
    });
    if (listedCompanies.value.length === 0) {
      infoMessage.value = 'Inga resultat';
    }
  } catch (error: any) {
    errorMessage.value = 'Något gick fel vid sökningen - ' + error.message;
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function onCompanyClick(company: Company) {
  loading.value = true;
  listedCompanies.value = [];

  try {
    const [companyHierarchy] = await companyService.getHierarchy({
      dataSource: dataSource.value,
      orgId: company.orgId,
      locale: 'SE'
    });

    if (companyHierarchy.parent && !showingCompanyHierarchy.value) {
      showingCompanyHierarchy.value = true;
      listedCompanies.value.push(companyHierarchy.parent, company);
      loading.value = false;
      return;
    }
  } catch (error: any) {
    errorMessage.value = 'Något gick fel vid hämtning av företagshierarki - ' + error.message;
    console.log(error);
    loading.value = false;
    return;
  }

  try {
    loading.value = true;
    const companyBalanceSheet = await companyService.getBalanceSheet({
      dataSource: dataSource.value,
      orgId: company.orgId,
      locale: 'SE'
    });
    infoMessage.value = 'Balansräkning hämtad, du skickas nu vidare...';
    store.balanceSheet = companyBalanceSheet;
    store.company = company;
  } catch (error: any) {
    errorMessage.value = 'Något gick fel vid hämtning av balansräkning ' + error.message;
    console.log(error);
    loading.value = false;
  }

  let legacySource = 'unknown';
  if (dataSource.value === 'UCBI') {
    legacySource = 'ucBI';
  } else if (dataSource.value === 'D&B') {
    legacySource = 'dunAndBradstreet';
  }

  legacyEmit('vue-company-select', {
    item: {
      id: company.orgId,
      name: company.name,
      data: { orgNumber: company.orgId, name: company.name, country: 'SE' },
      source: legacySource,
      country: 'SE',
      currency: 'SEK',
      isCorporateGroup: false
    }
  });
}

watch(dataSource, () => {
  searchTerm.value = '';
  listedCompanies.value = [];
  showingCompanyHierarchy.value = false;
  infoMessage.value = '';
  errorMessage.value = '';
});
</script>

<template>
  <div class="vm">
    <div class="modal" :class="{ 'is-active': open }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Läs in företag</p>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Datakälla</label>
            <div class="control">
              <div class="select">
                <select v-model="dataSource">
                  <option value="1" disabled>Dun & Bradstreet DK</option>
                  <option value="2" disabled>Morningstar</option>
                  <option value="3" disabled>Bizzdo Bokföring</option>
                  <option value="4" disabled>Bizzdo</option>
                  <option value="UCBI">UC Business Insight</option>
                  <option value="D&B">Dun & Bradstreet SE</option>
                  <option value="7" disabled>Dun & Bradstreet FI</option>
                  <option value="8" disabled>Dun & Bradstreet NO</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control has-icons-left" :class="{ 'is-loading': false }">
              <input v-model="searchTerm" class="input" type="text" placeholder="Namn / Organisationsnummer" />
              <span class="icon is-small is-left"><app-icon :icon="mdiMagnify" /></span>
            </div>
          </div>

          <progress v-if="loading" class="progress is-primary is-small"></progress>
          <div v-if="!loading && listedCompanies.length > 0" class="menu">
            <ul class="menu-list" :class="{ 'is-showing-hierarchy': showingCompanyHierarchy }">
              <li v-for="company in listedCompanies" :key="company.orgId">
                <a class="is-flex is-align-items-center" @click.prevent="onCompanyClick(company)">
                  <app-icon size="16" class="is-primary has-text-primary" :icon="mdiStarFourPoints" />
                  <span class="ml-4">{{ company.name }}</span>
                </a>
              </li>
            </ul>
          </div>

          <article v-if="errorMessage" class="message is-danger mb-4">
            <div class="message-body">{{ errorMessage }}</div>
          </article>

          <article v-if="infoMessage" class="message is-info mb-4">
            <div class="message-body">{{ infoMessage }}</div>
          </article>
        </section>

        <footer class="modal-card-foot is-justify-content-end">
          <div class="buttons">
            <button class="button">Lägg till företag manuellt</button>
            <button class="button">Läs in sparad fil</button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-card {
  max-height: 80vh !important;
}
ul.is-showing-hierarchy {
  li {
    padding-left: 24px;
    &:first-child {
      padding-left: 0;
    }
  }
}
</style>
