import { httpService } from '@/services/http-service';

type SearchRequest = {
  dataSource: string;
  searchTerm: string;
  locale: string;
};

type HierarchyRequest = {
  dataSource: string;
  orgId: string;
  locale: string;
};

type BalanceSheetRequest = {
  dataSource: string;
  orgId: string;
  locale: string;
};

export type Company = { name: string; orgId: string; parent: Company | null };

class CompanyService {
  async search(searchRequest: SearchRequest): Promise<Company[]> {
    return await httpService.post<Company[]>('/company/search-company', searchRequest);
  }

  async getHierarchy(hierarchyRequest: HierarchyRequest): Promise<Company[]> {
    return await httpService.post<any>('/company/hierarchy', hierarchyRequest);
  }
  //! Return value
  async getBalanceSheet(balanceSheetRequest: BalanceSheetRequest): Promise<any> {
    return await httpService.post<any>('/financial/financial-sheet', balanceSheetRequest);
  }
}

export const companyService = new CompanyService();
