export const balanceSheet = {
  assets: {
    _: 'Tillgångar',
    cash: 'Likvida medel',
    customerReceivables: 'Kundfodringar',
    fixed: 'Anläggningstillgångar',
    inventory: 'Varulager',
    otherClaims: 'Andra fodringar'
  },
  equities: {
    _: 'Eget kapital',
    nonRestrictedEquity: 'Fritt eget kapital',
    otherFunds: 'Övrigt bundet e.k.',
    shareCapital: 'Aktiekapital'
  },
  liabilities: {
    _: 'Skulder',
    otherShortDebts: 'Andra korta skulder',
    supplierDebts: 'Leverantörsskulder'
  }
};
