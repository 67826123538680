import type { LegacyConfig } from '@/models/legacy-config';

class LegacyConfigService {
  private configLoaded: Promise<void>;

  constructor() {
    this.configLoaded = new Promise((resolve) => {
      this.loadConfig(resolve);
    });
  }

  private loadConfig(resolve: () => void) {
    if ((window as any).legacyConfig) {
      resolve();
    } else {
      const checkInterval = setInterval(() => {
        if ((window as any).legacyConfig) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 100);
    }
  }

  public async get<K extends keyof LegacyConfig>(key: K): Promise<LegacyConfig[K]> {
    await this.configLoaded;
    return (window as any).legacyConfig[key];
  }

  public async getAll(): Promise<LegacyConfig> {
    await this.configLoaded;
    return (window as any).legacyConfig as LegacyConfig;
  }
}

export const legacyConfigService = new LegacyConfigService();
