import { onBeforeUnmount, onMounted } from 'vue';
import { useI18n, type Composer, type UseI18nOptions } from 'vue-i18n';

export function useGlobalI18n(options: UseI18nOptions = {}): Composer {
  const composer = useI18n({
    useScope: 'global',
    ...options
  }) as Composer;

  const syncLocaleWithLegacyApp = (legacyLanguage?: string) => {
    const newLanguage = legacyLanguage ?? (window as any).legacyLanguage;
    if (newLanguage === 'se' || !newLanguage) {
      composer.locale.value = 'sv';
    } else if (newLanguage) {
      composer.locale.value = newLanguage;
    }
  };

  syncLocaleWithLegacyApp();

  const handleLanguageChange = (event: CustomEvent) => {
    syncLocaleWithLegacyApp(event.detail);
  };

  onMounted(() => {
    window.addEventListener('legacyLanguageChanged', handleLanguageChange as EventListener);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('legacyLanguageChanged', handleLanguageChange as EventListener);
  });

  return composer;
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $i18n: Composer;
    $t: Composer['t'];
  }
}
