import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useStore = defineStore(
  'store',
  () => {
    const company = ref<any>({});
    const balanceSheet = ref<any>({});
    const debugWindowLeft = ref(true);
    const debugWindowExpanded = ref(false);
    return { company, balanceSheet, debugWindowLeft, debugWindowExpanded };
  },
  { persist: { storage: sessionStorage, key: 'bisgraf-store' } }
);
