import type { LegacyReduxState } from '@/models/legacy-redux-state';

interface LegacyStore {
  getState: () => any;
  subscribe: (listener: () => void) => () => void;
  dispatch: (action: any) => void;
}

class LegacyStoreService {
  private store: any | null = null;
  private storeReadyCallbacks: Array<() => void> = [];
  private listeners: Array<() => void> = [];

  constructor() {
    this.initStore();
  }

  private initStore() {
    if ((window as any).legacyReduxStore) {
      this.store = (window as any).legacyReduxStore;
      this.notifyStoreReady();
    } else {
      const checkInterval = setInterval(() => {
        if ((window as any).legacyReduxStore) {
          this.store = (window as any).legacyReduxStore;
          this.notifyStoreReady();
          clearInterval(checkInterval);
        }
      }, 100);
    }
  }

  private notifyStoreReady() {
    this.storeReadyCallbacks.forEach((callback) => callback());
    this.storeReadyCallbacks = [];
    this.store.subscribe(() => {
      this.listeners.forEach((listener) => listener());
    });
  }

  public getStore(): LegacyStore | null {
    return this.store;
  }

  public async getState<T = LegacyReduxState>(): Promise<T> {
    await this.storeIsReady();
    return this.store.getState();
  }

  public subscribe(listener: () => void): () => void {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  public dispatch(action: any): void {
    if (this.store) {
      this.store.dispatch(action);
    } else {
      console.warn('Redux store is not available');
    }
  }

  public storeIsReady(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.onStoreReady(() => {
        resolve();
      });
    });
  }

  public onStoreReady(callback: () => void) {
    if (this.store) {
      callback();
    } else {
      this.storeReadyCallbacks.push(callback);
    }
  }
}

export const legacyStoreService = new LegacyStoreService();
