import { cookieService } from '@/services/cookie-service';
import { legacyHttpService } from './legacy-http-service';

const SESSION_COOKIE_KEY = 'session';

type LoginRequest = {
  username: string;
  password: string;
};

type LoginResponse = {
  status: number;
  data?: { session: string; userId: string };
  error?: string;
};

class AuthService {
  get isLoggedIn() {
    return !!cookieService.get(SESSION_COOKIE_KEY);
  }

  async login(loginRequest: LoginRequest) {
    const { data } = await legacyHttpService.post<LoginResponse>('/authenticate', loginRequest);
    if (data?.session) {
      cookieService.set(SESSION_COOKIE_KEY, data.session);
    }
  }

  // async logout() {
  //   try {
  //     await http.post('/logout');
  //     cookie.del('session');
  //     return;
  //   } catch (error) {
  //     throw new Error(`Error loggin out: ${error.message}`);
  //   }
  // }
}

export const authService = new AuthService();
