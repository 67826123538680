import { legacyTranslationService } from '@/services/legacy-translation-service';
import { computed } from 'vue';

export function useLegacyTranslations() {
  const legacyTranslationsLoaded = computed(() => legacyTranslationService.isReady);

  const lt = computed(() => (key: string) => {
    return legacyTranslationService.translate(key);
  });

  return { lt, legacyTranslationsLoaded };
}
