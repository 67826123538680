import type { LegacyReduxState } from '@/models/legacy-redux-state';
import { legacyStoreService } from '@/services/legacy-store-service';
import { onMounted, onUnmounted, ref, type Ref, watch } from 'vue';

export function useLegacyStore<T = LegacyReduxState>(): {
  legacyState: Ref<T | null>;
  legacyDispatch: (action: any) => void;
} {
  const legacyState = ref<T | null>(null);
  let unsubscribe: (() => void) | null = null;

  const setupSubscription = () => {
    const store = legacyStoreService.getStore();
    if (store) {
      legacyState.value = store.getState();
      unsubscribe = store.subscribe(() => {
        legacyState.value = store.getState();
      });
    }
  };

  const legacyDispatch = (action: any) => {
    const store = legacyStoreService.getStore();
    if (store) {
      store.dispatch(action);
    } else {
      console.warn('Redux store is not available');
    }
  };

  onMounted(() => {
    legacyStoreService.onStoreReady(setupSubscription);
  });

  onUnmounted(() => {
    if (unsubscribe) {
      unsubscribe();
    }
  });

  return {
    legacyState: legacyState as Ref<T | null>,
    legacyDispatch
  };
}

/*
  Optional selector composable for accessing specific parts of Redux state
    - If you only want a component to re-render when specific pieces of state change
    - If you're accessing a deeply nested part of the state tree
    - If you have expensive computations that should only run when specific data changes
*/
export function useLegacyStateSelector<T, S>(
  selector: (state: T) => S
): {
  legacySelectedState: Ref<S | null>;
  legacyDispatch: (action: any) => void;
} {
  const { legacyState, legacyDispatch } = useLegacyStore<T>();
  const legacySelectedState = ref<S | null>(null);

  watch(
    legacyState,
    (newState) => {
      if (newState) {
        legacySelectedState.value = selector(newState);
      } else {
        legacySelectedState.value = null;
      }
    },
    { immediate: true }
  );

  return {
    legacySelectedState: legacySelectedState as Ref<S | null>,
    legacyDispatch
  };
}
