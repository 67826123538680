import '@/assets/styles/main.scss';
import mainStyles from '@/assets/styles/main.scss?inline';
import BisgrafDebug from '@/components/bisgraf-debug.ce.vue';
import ModalSelectCompany from '@/components/modal-select-company/modal-select-company.ce.vue';
import { dk } from '@/languages/dk/dk';
import { en } from '@/languages/en/en';
import { fi } from '@/languages/fi/fi';
import { no } from '@/languages/no/no';
import { sv } from '@/languages/sv/sv';
import { us } from '@/languages/us/us';
import LoginRegistrationPage from '@/pages/login-registration-page.ce.vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { defineCustomElement, h, provide, type App } from 'vue';
import { createI18n } from 'vue-i18n';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const i18n = createI18n({
  legacy: false,
  locale: 'sv',
  fallbackLocale: 'sv',
  messages: { dk, en, fi, no, sv, us }
});

const shadowDomStyles = mainStyles.replace(/:root/g, '').replace(/.vm .theme-light/g, '.vm');

function defineCustomElementWithDefaults(component: any) {
  return defineCustomElement({
    ...component,
    styles: [...(component.styles || []), shadowDomStyles],
    configureApp: (app: App) => {
      app.use(i18n);
      app.use(pinia);
    },
    setup(props: any) {
      provide('i18n', i18n);
      provide('pinia', pinia);
      return () => h(component, props);
    }
  });
}

const LoginRegistrationPageCustomElement = defineCustomElementWithDefaults(LoginRegistrationPage);
const ModalSelectCompanyCustomElement = defineCustomElementWithDefaults(ModalSelectCompany);
const BisgrafDebugCustomElement = defineCustomElementWithDefaults(BisgrafDebug);

export { BisgrafDebugCustomElement, LoginRegistrationPageCustomElement, ModalSelectCompanyCustomElement };

export function register() {
  customElements.define('vue-login-registration-page', LoginRegistrationPageCustomElement);
  customElements.define('vue-modal-select-company', ModalSelectCompanyCustomElement);
  customElements.define('vue-bisgraf-debug', BisgrafDebugCustomElement);
}
