import { ref, type Ref } from 'vue';

class LegacyTranslationService {
  private translationsLoaded: Ref<boolean>;
  private translations: Record<string, string> = {};

  constructor() {
    this.translationsLoaded = ref(false);
    this.initTranslations();
  }

  private async initTranslations() {
    if ((window as any).legacyTranslations) {
      this.translations = (window as any).legacyTranslations;
      this.translationsLoaded.value = true;
    } else {
      const checkInterval = setInterval(() => {
        if ((window as any).legacyTranslations) {
          this.translations = (window as any).legacyTranslations;
          this.translationsLoaded.value = true;
          clearInterval(checkInterval);
        }
      }, 100);
    }
  }

  get isReady(): boolean {
    return this.translationsLoaded.value;
  }

  translate(key: string): string {
    if (this.isReady) {
      const translation = this.getNestedValue(this.translations, key);
      if (translation) {
        return translation;
      } else {
        console.warn(`Legacy translation not found: ${key}`);
        return key;
      }
    } else {
      return key;
    }
  }

  private getNestedValue(obj: any, path: string): string | undefined {
    return path.split('.').reduce((current, key) => {
      return current && typeof current === 'object' ? current[key] : undefined;
    }, obj);
  }
}

export const legacyTranslationService = new LegacyTranslationService();
