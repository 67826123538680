<script setup lang="ts">
import ForgotPasswordForm from '@/components/login-registration/forgot-password-form.ce.vue';
import LoginForm from '@/components/login-registration/login-form.ce.vue';
import RegistrationForm from '@/components/login-registration/registration-form.ce.vue';
import { useGlobalI18n } from '@/composables/use-global-i18n';
import { useLegacyConfig } from '@/composables/use-legacy-config';
import { useLegacyStore } from '@/composables/use-legacy-store';
import { useLegacyTranslations } from '@/composables/use-legacy-translations';
import { authService } from '@/services/auth-service';
import { onBeforeMount, ref } from 'vue';

type LoginState = 'login' | 'register' | 'forgotPassword';
const loginState = ref<LoginState>('login');

const { t, availableLocales, locale } = useGlobalI18n();
const { lt, legacyTranslationsLoaded } = useLegacyTranslations();
const { legacyState } = useLegacyStore();
const { topbarLogo } = useLegacyConfig();

onBeforeMount(() => {
  if (authService.isLoggedIn) {
    document.location = '/graph';
  }
});
</script>

<template>
  <div class="vm">
    <section class="hero is-fullheight is-fullwidth">
      <div class="hero-body">
        <div class="container is-flex is-flex-direction-column is-align-items-center">
          <img :src="`/img/${topbarLogo}`" class="logo" />
          <login-form v-if="loginState === 'login'" />
          <registration-form v-if="loginState === 'register'" />
          <forgot-password-form v-if="loginState === 'forgotPassword'" />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.logo {
  height: 60px;
  margin-bottom: 15px;
  margin-top: -75px;
}
</style>
