<script setup lang="ts">
import { useGlobalI18n } from '@/composables/use-global-i18n';
import { authService } from '@/services/auth-service';
import { ref, watch } from 'vue';
import { mdiEmail, mdiLock } from '@mdi/js';
import AppIcon from '@/components/app-icon.ce.vue';

const loading = ref(false);

const { t } = useGlobalI18n();

const formData = ref({
  username: '',
  password: ''
});

watch(
  () => formData.value,
  () => {
    errorMessage.value = '';
  },
  { deep: true }
);

const errorMessage = ref('');

async function onLogin() {
  try {
    await authService.login(formData.value);
    document.location = '/graph'; //! Denna ska nog vara lite mer dynamisk
  } catch (error: any) {
    errorMessage.value = error.message;
    console.log(error.message);
  }
}
</script>
<template>
  <div class="box">
    <article v-if="errorMessage" class="message is-danger mb-4">
      <div class="message-body">{{ errorMessage }}</div>
    </article>
    <!-- <p class="title">Logga in</p> -->
    <!-- <p class="subtitle">Ange användarnamn och lösenord</p> -->
    <form @submit.prevent="onLogin">
      <div class="field">
        <label class="label" for="username">E-post</label>
        <div class="control has-icons-left">
          <input
            v-model="formData.username"
            tabindex="1"
            class="input"
            type="text"
            placeholder="Ange din e-postadress"
            required
          />
          <span class="icon is-small is-left"><app-icon :icon="mdiEmail" /></span>
        </div>
      </div>

      <div class="field">
        <div class="is-flex is-justify-content-space-between">
          <label class="label" for="password">Lösenord</label>
          <a href="#" @click.prevent="">Glömt lösenord?</a>
        </div>
        <div class="control has-icons-left">
          <input
            v-model="formData.password"
            tabindex="2"
            class="input"
            type="password"
            placeholder="Ange ditt lösenord"
            required
          />
          <span class="icon is-small is-left"><app-icon :icon="mdiLock" /></span>
        </div>
      </div>

      <button class="button is-primary is-fullwidth mt-6" tabindex="3" type="submit" :class="{ 'is-loading': loading }">
        Logga in
      </button>
      <button class="button is-fullwidth mt-4" tabindex="4" type="button" :class="{ 'is-loading': loading }">
        Registrera konto
      </button>
      <!-- <p class="mt-5">Glömt ditt lösenord? <a href="#" @click.prevent="">Klicka här</a></p> -->

      <slot />
    </form>
  </div>
</template>

<style scoped lang="scss">
.box {
  width: clamp(360px, 100%, 360px);
}
</style>
